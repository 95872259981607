import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Dialog, Divider, Typography } from "@mui/material";
import useStyles from "./styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Calendar from "../common/Calendar";
import { convertMonth } from "utils";
import { getAuth } from "firebase/auth";
import ProfessionalSelect from "../common/ProfessionalSelect";
import ReservationCard from "../common/ReservationCard";
import ReservationListItem from "../common/ReservationListItem";
import api from "../../services/api";
import payout from "../../services/payout";
import scheduleService, { getDatedBookings } from "../../services/scheduleService";
import { Link, Config, Signature, Question, Exit, Clients } from "../common/icons/Icons";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from "@mui/icons-material/Add";
import NewScheduleDialog from "../newScheduleDialog";
import clientAction from "../../store/actions/clientAction";
import sectorAction from "../../store/actions/sectorAction";
import { useDispatch } from "react-redux";
import businessAction from "../../store/actions/businessAction";
import moment from "moment";
import LogoutDialog from "./components/LogoutDialog";
import styles from '../../styles/schedule/index.scss';

const Schedule = (props) => {
	const [auth, setAuth] = useState(getAuth());
	const dispatch = useDispatch();
	const classes = useStyles();
	const navigate = useNavigate();
	const [update, setUpdate] = useState(false);

	const [professionals, setProfessionals] = useState([]);
	const [hours, setHours] = useState([]);
	const [now, setNow] = useState(new Date(Date.now()));
	const [date, setDate] = useState(new Date(Date.now()));
	const [showCalendar, setShowCalendar] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [business, setBusiness] = useState(null);
	const [loading, setLoading] = useState(false);
	const [newsCount, setNewsCount] = useState(0);
	const [lastReminderCheckDate, setLastReminderCheckDate] = useState(new Date(0));
	const [reservations, setReservations] = useState([]);
	const [reservationDates, setReservationDates] = useState([]);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [openDescripition, setOpenDescription] = useState(false);
	const [selectedBookingTransaction, setSelectedBookingTransaction] = useState(null);
	const [selectedPaymentList, setSelectedPaymentList] = useState(null);
	const [filtredProfessional, setFiltredProfessional] = useState(null);
	const [reservationsLoading, setReservationsLoading] = useState(true);
	const [paymentLoading, setPaymentLoading] = useState(false);
	const [transactionLoading, setTransactionLoading] = useState(false);
	const [listView, setListView] = useState(false);
	const [openAppRedirectModal, setOpenAppRedirectModal] = useState(false);
	const [AppRedirectMessage, setAppRedirectMessage] = useState("");
	const [myProfessionalId, setMyProfessionalId] = useState(null);
	const [openFreeTest, setOpenFreeTest] = useState(false);
	const [reaminingFreeTestDays, setReaminingFreeTestDays] = useState(0);
	const [openNewSchedule, setOpenNewSchedule] = useState(false);

	// Modal Logout
	const [isOpenModalLogout, setIsOpenModalLogout] = useState(false);

	useEffect(() => {
		window.addEventListener("resize", (e) => {
			setUpdate(!update);
		});
	}, []);

	const getMyProfessionalId = (me, professionals, business) => {
		professionals.forEach((professional) => {
			if (
				me.id != business.ownerId &&
				professional.phone == me.phone &&
				(professional.permission == "owner" || professional.permission == "manager")
			) {
				setMyProfessionalId(professional.id);
			}
		});
	};

	const fetchReservations = async (businessId, date, setReservations, setLoading) => {
		try {
			const formattedDate = moment(date).format('YYYY-MM-DD');
			const nextDay = moment(date).add(1, 'days').format('YYYY-MM-DD');
			const response = await scheduleService.getDatedBookings(businessId, formattedDate, nextDay);
			setReservations(response.data.bookings);
		} catch (error) {
			setAppRedirectMessage(
				"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando com seus clientes!"
			);
			setOpenAppRedirectModal(true);
			setReservationsLoading(false);
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (business) {
			dispatch(businessAction.getBusiness());
			dispatch(clientAction.getClients(business?.id, myProfessionalId));
			dispatch(sectorAction.getSector(business?.id));
			api
				.get(`subscription?business_id=${business.id}`)
				.then((res) => {
					let finalFreeTestData = new Date(business.createdAt);
					finalFreeTestData.setDate(finalFreeTestData.getDate() + 7);
					if (finalFreeTestData.getTime() > Date.now()) {
						setReaminingFreeTestDays(Math.floor((finalFreeTestData.getTime() - Date.now()) / 86400000));
						setOpenFreeTest(true);
					}
					if (finalFreeTestData.getTime() <= Date.now() && new Date(res.data[0].expiration).getTime() <= Date.now()) {
						//navigate("/expired");
					}
				})
				.catch((err) => {
					//console.log(err);
				});
			api
				.get("feed?page=1&per_page=20")
				.then((res) => {
					setNotifications(res.data);
				})
				.catch((err) => {
					//console.log(err);
				});
		}
	}, [business]);

	useEffect(() => {
		let arr = [];
		for (var i = 0; i < 24; i++) {
			arr.push(i);
		}
		setHours(arr);
	}, []);

	setTimeout(() => {
		setInterval(() => {
			setNow(new Date(Date.now()));
		}, 60000);
	}, now.getSeconds() * 1000);

	useEffect(() => {
		let lastCheck = localStorage.getItem("@prit-web/lastReminderCheck");
		if (lastCheck !== null) {
			setLastReminderCheckDate(new Date(lastCheck));
			let count = 0;
			notifications.forEach((element) => {
				if (lastReminderCheckDate < new Date(element.createdAt)) {
					count++;
				}
			});
			setNewsCount(count);
		}
	}, []);

	useEffect(() => {
		let count = 0;
		if (notifications) {
			notifications.forEach((element) => {
				if (lastReminderCheckDate < new Date(element.createdAt)) {
					count++;
				}
			});
			setNewsCount(count);
		}
	}, [notifications, lastReminderCheckDate]);

	const handleCheckAllReminders = () => {
		localStorage.setItem("@prit-web/lastReminderCheck", Date.now());
		setLastReminderCheckDate(Date.now());
		setNewsCount(0);
	};

	useEffect(() => {
		const request = setInterval(() => {
			setLoading(true);
			if (auth?.currentUser) {
				api
					.get(
						"schedule/?is_message_bookings=false&is_payment_types=false&is_message_clients=true&is_sectors=false&is_pricing_type=false"
					)
					.then((res) => {
						if (!res.data.business) {
							setLoading(false);
							setBusiness(null);
							setAppRedirectMessage(
								"Parece que você ainda não possui um estabelecimento... Baixe agora o Prit para Profissionais e crie um novo estabelecimento para você!"
							);
							setOpenAppRedirectModal(true);
						} else {
							setBusiness(res.data.business);
							setProfessionals(res.data.professionals);
						}
						setReservationDates(res.data.bookingDates);
						setReservationsLoading(false);
						setReservations(res.data.bookings);
						setLoading(false);
						getMyProfessionalId(res.data.me, res.data.professionals, res.data.business);
						clearInterval(request);
					})
					.catch((err) => {
						setAppRedirectMessage(
							"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando com seus clientes!"
						);
						setOpenAppRedirectModal(true);
						setLoading(false);
						setReservationsLoading(false);
						//console.log(err);
					});
			} else {
				setAuth(getAuth());
				if (auth.currentUser === null) {
					navigate("/login");
				}
			}
		}, 1000);
		return () => clearInterval(request);
	}, []);

	useEffect(() => {
		setReservationsLoading(true);
	}, [date]);

	useEffect(() => {
		if (business?.id) {
			fetchReservations(business.id, date, setReservations, setLoading);
		}
	}, [date]);

	useEffect(() => {
		if (selectedBooking?.bookingTransactionId) {
			api
				.get("booking_transaction/" + selectedBooking?.bookingTransactionId)
				.then((res) => {
					setSelectedBookingTransaction(res.data);
					setTransactionLoading(false);
				})
				.catch((err) => {
					setAppRedirectMessage(
						"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando comseus clientes!"
					);
					setOpenAppRedirectModal(true);
					setTransactionLoading(false);
					//console.log(err);
				});
		}
		if (selectedBooking?.bookingTransactionId) {
			api
				.get(
					"payment/list/grouped/payments/" +
					selectedBooking?.bookingTransactionId
				)
				.then((res) => {
					setSelectedPaymentList(res.data);
					setPaymentLoading(false);
				})
				.catch((err) => {
					setAppRedirectMessage(
						"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando comseus clientes!"
					);
					setOpenAppRedirectModal(true);
					setPaymentLoading(false);
					//console.log(err);
				});
		}
	}, [selectedBooking, transactionLoading, paymentLoading]);

	useEffect(() => {
		if (selectedBooking && !transactionLoading && !paymentLoading) {
			setOpenDescription(true);
		}
	}, [transactionLoading, paymentLoading]);

	return (
		<div className={classes.calendarContainer}>
			<div className="calendar">
				<div className="date-header-name">
					<div
						style={{
							backgroundColor: "#D9D9D9",
							backgroundImage: business?.thumbnailUrl ? `url(${business.thumbnailUrl})` : "none",
							backgroundSize: "cover",
							backgroundPosition: "center",
							width: "40px",
							height: "40px",
							borderRadius: "100%",
							marginRight: "10px",
						}}></div>

					<Typography
						style={{
							fontSize: "14px",
							color: "#838282",
							fontWeight: "bold",
							textAlign: "center",
						}}>
						{business?.name}
					</Typography>
				</div>
				<Divider />

				<Calendar
					update
					date={date}
					onChange={() => setShowCalendar(false)}
					setDate={setDate}
					daysToNotify={reservationDates}
				/>
				<div
					className={`date-header ${classes.menu}`}
					style={{
						// display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
						paddingLeft: "20px",
					}}>
					<Typography
						style={{
							fontSize: "15px",
							color: "darkgray",
							textAlign: "left",
							marginBottom: "13px",
						}}>
						<Link style={{ marginRight: "10px" }} />
						{"Meu Link"}
					</Typography>
					<Typography
						style={{
							fontSize: "15px",
							color: "darkgray",
							textAlign: "left",
							marginBottom: "13px",
						}}>
						<Config style={{ marginRight: "10px" }} />

						{"Configurações da empresa"}
					</Typography>
					<Typography
						style={{
							fontSize: "15px",
							color: "darkgray",
							textAlign: "left",
							marginBottom: "13px",
						}}>
						<Clients style={{ marginRight: "10px" }} />

						{"Clientes"}
					</Typography>
					<Typography
						style={{
							fontSize: "15px",
							color: "darkgray",
							textAlign: "left",
							marginBottom: "13px",
						}}>
						<Signature style={{ marginRight: "10px" }} />

						{"Assinatura"}
					</Typography>
					<Typography
						style={{
							fontSize: "15px",
							color: "darkgray",
							textAlign: "left",
							marginBottom: "13px",
						}}>
						<Question style={{ marginRight: "10px" }} />

						{"Perguntas Frequentes"}
					</Typography>
					<Typography
						style={{
							fontSize: "15px",
							color: "darkgray",
							textAlign: "left",
							marginBottom: "13px",
						}}
						onClick={() => setIsOpenModalLogout(true)}
					>
						<Exit style={{ marginRight: "10px" }} />

						{"Sair"}
					</Typography>
				</div>
			</div>

			<Divider orientation="vertical" flexItem className="divider" />

			<div className={classes.reservationList}>
				<div
					className="date-header"
					style={{ paddingTop: "11px", margin: "21px", width: "100%", alignItems: "center" }}>
					<Typography
						style={{
							color: "darkgray",
							fontSize: "16px",

							marginRight: "10px",
							fontWeight: "bold",
						}}>
						{` ${date.getDate()} de ${convertMonth(date.getMonth())} `}
					</Typography>

					<button
						onClick={() => setDate(new Date(date.setDate(date.getDate() - 1)))}
						style={{
							cursor: "pointer",
							background: "none",
							border: "1px solid darkgray",
							borderRadius: "4px",
							padding: "5px",
							marginRight: "10px",
						}}>
						<ArrowBackIosNewIcon
							style={{
								color: "#616161",
								fontSize: "18px",
								cursor: 'pointer'
							}}
						/>
					</button>

					<button
						onClick={() => setDate(new Date(date.setDate(date.getDate() + 1)))}
						style={{
							cursor: "pointer",
							background: "none",
							border: "1px solid darkgray",
							borderRadius: "4px",
							padding: "5px",
						}}>
						<ArrowForwardIosIcon
							style={{
								color: "#616161",
								fontSize: "18px",
							}}
						/>
					</button>
				</div>

				<Divider />
				{professionals?.length > 0 && (
					<div style={{ display: "flex", marginLeft: "30px" }}>
						<div
							onClick={() => setFiltredProfessional(null)}
							style={{
								width: "100px",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignContent: "center",
							}}>
							<AccountCircleIcon
								style={{
									color: "darkgray",
									width: "30px",
									height: "30px",
									cursor: "pointer",
									borderRadius: "100%",
									alignSelf: "center",
									margin: "10px",
								}}
							/>
							<div style={{ width: "100vw" }}>
								<Typography
									style={{
										fontSize: "15px",
										color: "darkgray",
										textAlign: "center",
										width: "100px",
									}}>
									{" Todos "}
								</Typography>
							</div>
						</div>
						<div style={{ display: "flex", width: "60vw", padding: "15px" }}>
							{professionals.map((professional) => (
								<ProfessionalSelect
									key={professional.id}
									name={professional.name}
									onClick={() => setFiltredProfessional(professional)}
									selected={filtredProfessional?.id === professional?.id}
								/>
							))}
						</div>
					</div>
				)}
				<Divider />

				{listView ? (
					<div>
						{reservations &&
							reservations.map((booking) => {
								const { startTime, professional, service, client } = booking;
								const bookingDate = new Date(startTime);
								if (filtredProfessional == null || professional.id === filtredProfessional.id) {
									if (
										bookingDate.getDate() === date.getDate() &&
										bookingDate.getMonth() === date.getMonth() &&
										bookingDate.getFullYear() === date.getFullYear()
									) {
										return (
											<ReservationListItem
												key={booking.id}
												business={business}
												booking={booking}
												bookingDate={bookingDate}
												onClick={() => {
													setSelectedBooking(booking);
													setPaymentLoading(true);
													setTransactionLoading(true);
												}}
											/>
										);
									}
								}
								return null;
							})}
						{reservations.length === 0 && (
							<Typography
								style={{
									fontSize: "22px",
									textAlign: "center",
									color: "gray",
									marginTop: "15vh",
									alignSelf: "center",
								}}>
								Não existem reservas para a data selecionada
							</Typography>
						)}
					</div>
				) : (
					<div
						style={{ paddingLeft: "30px", paddingBottom: "100px", cursor: "pointer" }}
						onClick={() => setOpenNewSchedule(true)}>
						{hours.map((hour) => (
							<div key={hour} style={{ padding: "25px 15px", height: "10px" }}> 
								{date.getDate() === now.getDate() &&
									((now.getHours() === hour && now.getMinutes() <= 30) ||
										(now.getHours() === hour - 1 && now.getMinutes() > 30)) ? (
									<Typography
										style={{
											fontSize: "14px",
											color: "darkgray",
											position: "relative",
											top: now.getHours() === hour ? now.getMinutes() : now.getMinutes() - 60 + "px",
											padding: 0,
											margin: 0,
											color: "rgb(255, 105, 135)",
										}}>
										{(now.getHours() < 10 ? "0" + now.getHours() : now.getHours()) +
											":" +
											(now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes())}
										<Divider
											style={{
												backgroundColor: "rgb(255, 105, 135)",
												position: "relative",
												left: "50px",
												bottom: "10px",
												width: "98%",
											}}
										/>
									</Typography>
								) : (
									<Typography style={{ fontSize: "14px", color: "darkgray", padding: 0, margin: 0 }}>
										{(hour < 10 ? "0" + hour : hour) + ":00"}
										<Divider
											style={{
												backgroundColor: "#cacaca",
												position: "relative",
												left: "50px",
												bottom: "10px",
												width: "98%",
											}}
										/>
									</Typography>
								)}
								<div style={{ display: "flex" }} className="reservationContainer">
									{reservations &&
										reservations.map((booking) => {
											const { startTime, professional, service, client } = booking;
											const bookingDate = new Date(startTime);
											const endTime = new Date(bookingDate.getTime() + service.duration * 60000);

											const formatTime = (date) => {
												const hours = date.getHours().toString().padStart(2, "0");
												const minutes = date.getMinutes().toString().padStart(2, "0");
												return `${hours}:${minutes}`;
											};

											const startTimeFormatted = formatTime(bookingDate);
											const endTimeFormatted = formatTime(endTime);

											if (filtredProfessional == null || professional.id === filtredProfessional.id) {
												if (
													bookingDate.getDate() === date.getDate() &&
													bookingDate.getMonth() === date.getMonth() &&
													bookingDate.getFullYear() === date.getFullYear()
												) {
													if (
														(bookingDate.getHours() === hour && bookingDate.getMinutes() < 60) ||
														(bookingDate.getHours() === hour - 1 && bookingDate.getMinutes() >= 45)
													) {
														if (booking.status !== "cancelled" && booking.status !== "inactive") {
															return (
																<div style={{marginTop:(-10+(60*(bookingDate.getMinutes()/60)))+"px", width:'fit-content'}}>
																	<ReservationCard
																		key={booking.id}
																		clientName={client?.name}
																		serviceName={service?.name}
																		professionalName={professional?.name}
																		startTime={startTimeFormatted}
																		endTime={endTimeFormatted}
																		onClick={() => {
																			setSelectedBooking(booking);
																			setPaymentLoading(true);
																			setTransactionLoading(true);
																		}}
																	color={professional?.color}
																	/>
																</div>
															);
														}
													}
												}
											}
											return null;
										})}
								</div>
							</div>
						))}
					</div>
				)}
				<div
					className="floating-button"
					onClick={() => setOpenNewSchedule(true)}>
					<AddIcon style={{ fontSize: "30px" }} />
				</div>
			</div>

			<style>
				{`
				.date-header-name {
					display: flex;
					align-items: center;
					width: auto;
					margin: 9% 0 5% 5%;
				}
				.calendar {
					background-color: #eeeeee;
					width: 328px;
					position: sticky;
					top: 0; 
					z-index: 10; 
					overflow-y: auto; 
					height: 100vh;
				}
				.date-header {
					display: flex; 
				}
					.floating-button {
                    position: fixed;
                    bottom: 20px;
                    right: 20px;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background-color: #007bff;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                    display: none;
                }

				@media (max-width: 800px) {
					.calendar {
						width: 100%;
						height: auto;
						overflow: hidden;
					}

					.floating-button {
                        display: flex; 
                    }

					.date-header {
						display: none;
					}
					.date-header-name {
						display: none;
					}
				}
				
		`}
			</style>
			<Dialog
				open={openNewSchedule}
				fullScreen
				PaperProps={{
					style: { backgroundColor: "transparent" },
				}}>
				<NewScheduleDialog date={date} onClose={() => setOpenNewSchedule(false)}></NewScheduleDialog>
			</Dialog>
			{isOpenModalLogout && (
				<LogoutDialog isOpen={isOpenModalLogout} handleClose={() => setIsOpenModalLogout(!isOpenModalLogout)} />
			)}
		</div>
	);
};

export default Schedule;
