import api from "./api";

export const getClients = (businessId,myProfessionalId) => {
	return api.get("client?business_id=" + businessId + "&per_page=9999&page=1&field=name&order=asc"+(myProfessionalId?"&professional_id="+myProfessionalId:""))
};

export const postClient = (body) => {
	return api.post("client", body)
};

export default {
	getClients,postClient
};