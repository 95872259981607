import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignUp from "./views/signUpPage";
import LoginPage from "./views/loginPage";
import CodePage from "./views/codePage";
import PersonalDataPage from "./views/personalDataPage";
import WelcomePage from "./views/welcomePage";
import SchedulePage from "./views/schedulePage";
import NewReservationPage from "./views/newReservationPage";
import ExpiredPage from "./views/expiredPage";
import Payment from "./views/payment";
import PaymentMethods from "./components/payment/paymentMethods";
import PaymentPix from "./components/payment/paymentPix";
import PaymentCredit from "./components/payment/paymentCredit";
import CurrentPlan from "./components/payment/currentPlan";
import PaymentPixQrCode from "./components/payment/paymentPixQrCode";
import DetailsPayment from "./components/payment/detailsPayment";
import PaymentCancel from "./components/payment/paymentCancel";
import PaymentCancelFeedback from "./components/payment/paymentCancelFeedback.jsx";
import Address from "./components/address";
import SelectSector from "./components/selectSector";
import SelectCategories from "./components/selectCategories";

const Router = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" exact element={<Payment />} />
				<Route path="/login" exact element={<LoginPage />} />
				<Route path="/login/code" exact element={<CodePage />} />
				<Route path="/login/data" exact element={<PersonalDataPage />} />
				<Route path="/welcome" exact element={<WelcomePage />} />
				<Route path="/schedule" exact element={<SchedulePage />} />
				<Route path="/reservation/new" exact element={<NewReservationPage />} />
				<Route path="/menu/:id/:token" exact element={<Payment />} />
				<Route path="/menu" exact element={<Payment />} />
				<Route path="/menu/methods" exact element={<PaymentMethods />} />
				<Route path="/menu/plan" exact element={<CurrentPlan />} />
				<Route path="/menu/method/pix" exact element={<PaymentPix />} />
				<Route path="/menu/qrcode/pix" exact element={<PaymentPixQrCode />} />
				<Route path="/menu/method/credit" exact element={<PaymentCredit />} />
				<Route path="/menu/details" exact element={<DetailsPayment />} />
				<Route path="/menu/cancelar" exact element={<PaymentCancel />} />
				<Route path="/menu/cancelar/feedback" exact element={<PaymentCancelFeedback />} />
				<Route path="/address" exact element={<Address />} />
				<Route path="/selecionar-setores" exact element={<SelectSector />} />
				<Route path="/selecionar-categorias" exact element={<SelectCategories />} />
				<Route path="/expired" exact element={<ExpiredPage />} />
			</Routes>
		</BrowserRouter>
	);
};

export default Router;
