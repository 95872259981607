import api from "./api";

export const getBusiness = () => {
	return api.get("me/businesses");
};

export const putBusinessAddress = (id, address) => {
	return api.put(`business/${id}/address`, address);
};

export const postBusiness = (data) => {
	return api.post(`business`, data);
};

export default {
	getBusiness,
	putBusinessAddress,
	postBusiness
};
