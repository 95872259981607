import React from "react";
import { Divider, Typography } from "@mui/material";
import { QueryBuilder } from "@material-ui/icons";
import { moneyMask } from "../../utils";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: "#eeeeee",
		borderRadius: "30px",
		margin: "20px",
		boxShadow: "0px 0px 5px #888888",
		display: "flex",
		justifyContent: "start",
		cursor: "pointer",
		width: "40vw",

	},
	statusLabel: {
		color: "white",
		padding: "15px",
		borderRadius: "45px",
		margin: "0 15px",
		marginTop: "15px",
		textAlign: "center",
		textTransform: "uppercase",
		fontSize: "15px",
	},
	clockIcon: {
		width: "15px",
		height: "15px",
		color: "lightgray",
		margin: "3px",
	},
	hours: {
		fontWeight: "bold",
		color: "gray",
		width: "30px",
		fontSize: "15px",
		textAlign: "center",
	},
}));

const ReservationListItem = (props) => {
	const classes = useStyles();
	const {business, booking, bookingDate, onClick } = props;

	return (
		<>
			<div className={classes.container} onClick={onClick}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						margin: "15px",
						alignContent: "center",
						justifyContent: "space-around",
					}}>
					<Typography
						style={{
							fontWeight: "bold",
							color: "gray",
							width: "60px",
							fontSize: "15px",
							textAlign: "center",
						}}>
						{(bookingDate.getHours() < 10 ? "0" + bookingDate.getHours() : bookingDate.getHours()) +
							":" +
							(bookingDate.getMinutes() < 10 ? "0" + bookingDate.getMinutes() : bookingDate.getMinutes())}
					</Typography>
					<Divider flexItem style={{ fontWeight: "bold", color: "gray", width: "90%", minWidth: "20px" }}></Divider>
					<Typography
						style={{
							fontWeight: "bold",
							color: "gray",
							width: "60px",
							fontSize: "15px",
							textAlign: "center",
						}}>
						{(new Date(booking?.endTime).getHours() < 10
							? "0" + new Date(booking?.endTime).getHours()
							: new Date(booking?.endTime).getHours()) +
							":" +
							(new Date(booking?.endTime).getMinutes() < 10
								? "0" + new Date(booking?.endTime).getMinutes()
								: new Date(booking?.endTime).getMinutes())}
					</Typography>
				</div>
				<Divider
					orientation="vertical"
					flexItem
					style={{
						color: "gray",
					}}></Divider>
				<div
					style={{
						width: "80%",
					}}>
					<div>
						<div
							style={{
								display: "flex",
							}}>
							<div
								style={{
									marginTop: "30px",
									marginLeft: "30px",
									width: "90%",
								}}>
								<Typography
									style={{
										fontSize: "15px",
										fontWeight: "bold",
										color: "gray",
									}}>
									{booking?.service?.name}
								</Typography>
								<Typography
									style={{
										fontSize: "15px",
										color: "gray",
									}}>
									{"com " + booking?.professional?.name}
								</Typography>
								<div
									style={{
										display: "flex",
										marginTop: "10px",
										marginBottom: "10px"
									}}>
									<QueryBuilder className={classes.clockIcon}></QueryBuilder>
									<Typography
										style={{
											color: "lightgray",
											fontSize: "15px",
										}}>
										{booking?.duration + "m"}
									</Typography>
									<Typography
										style={{
											marginLeft: "15px",
											color: "#3689ea",
											fontSize: "15px",
										}}>
										{booking?.pricingType?.name + " " + moneyMask(booking?.price * 100,business.currency)}
									</Typography>
								</div>
							</div>
							<Divider flexItem orientation="vertical"></Divider>
							<div
								style={{
									alignContent: "end",
									width: "60%",
									margin: "20px 15px",
									marginLeft:"80px"
								}}>
								<Typography
									style={{
										color: "white",
										backgroundColor: booking?.confirmReservation ? "lightgray" : "#3689ea",
										padding: "15px",
										borderRadius: "45px",
										margin: "0 15px",
										marginTop: "15px",
										textAlign: "center",
										textTransform: "uppercase",
										fontSize: "15px",
									}}>
									{booking?.confirmReservation ? "confirmado" : "agendado"}
								</Typography>
								<div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
									{/* <Typography
										style={{
											color: "gray",
											fontSize: "15px",
											marginRight: "20px",
										}}>
										{"Total:"}
									</Typography>
									<Typography
										style={{
											textAlign: "center",
											color: "#3689ea",
											fontSize: "15px",
										}}>
										{moneyMask(booking.price * 100,business.currency)}
									</Typography> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default ReservationListItem;
