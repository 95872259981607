import React, { Fragment, useEffect, useState } from "react";
import useStyles from "./styles";
import { Button, Container, Dialog, Divider, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import businessAction from "../../store/actions/businessAction";
import ButtonCommom from "../common/Button";
import CoreContainer from "../common/new/container";
import CoreAppBar from "../common/new/appBar";
import sectorService from "../../services/sectorService";
import { CheckBox, Square } from "@mui/icons-material";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@material-ui/icons";
import CoreButton from "../common/new/button";
import businessService from "../../services/businessService";
import SpinnerLoading from "../common/spinnerLoading/SpinnerLoading";

const SelectCategories = (props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setLoading] = useState(true);
	const [openModal, setOpenModal] = useState(false);
	const [modalMessage, setModalMessage] = useState("");
	const { fullName, email, businessName, phone, sectorId } = location?.state || {};
	const [selectCategoryIds, setSelectedCategoryIds] = useState([]);
	const [categories, setCategories] = useState([]);

	const dispatch = useDispatch();

	useEffect(() => {
		setLoading(true);
		sectorService.getSectorById(sectorId).then((res) => {
			setCategories(res.data.categories);
			setLoading(false);
		});
	}, []);

	return (
		<CoreContainer style={{ gap: "16px" }}>
			<SpinnerLoading loading={loading}></SpinnerLoading>
			<CoreAppBar progress={90} />

			<Typography style={{ color: "#3E3E3E", fontSize: "22px", textAlign: "start", fontWeight: "bold" }}>
				Com o que você trabalha?
			</Typography>

			<div style={{ overflowY: "scroll", overflowX: "hidden", width: "100%", gap: "16px" }}>
				{categories.map((category) => (
					<div
						style={{
							backgroundColor: " #F1F1F1",
							padding: "8px 16px",
							borderRadius: "8px",
							cursor: "pointer",
							display: "flex",
							justifyContent: "space-between",
							marginBottom: "8px",
							marginRight: "8px",
						}}
						onClick={() => {
							if (selectCategoryIds.includes(category.id)) {
								setSelectedCategoryIds(selectCategoryIds.filter((id) => id !== category.id));
							} else {
								setSelectedCategoryIds([...selectCategoryIds, category.id]);
							}
						}}>
						<Typography style={{ color: "#3E3E3E", fontSize: "16px" }}>{category.name}</Typography>
						{selectCategoryIds.includes(category.id) ? (
							<CheckBoxOutlined htmlColor="#3E3E3E" />
						) : (
							<CheckBoxOutlineBlank htmlColor="#3E3E3E" />
						)}
					</div>
				))}
			</div>

			<Dialog
				open={openModal}
				PaperProps={{
					style: { borderRadius: 20, maxWidth: 400 },
				}}>
				<Typography style={{ margin: "30px", textAlign: "center", fontSize: "calc(0.5vw + 10px)" }}>
					<strong className={classes.littlePurple}>{modalMessage}</strong>
				</Typography>
				<Divider></Divider>
				<Button
					className={classes.littleBlue}
					style={{ padding: "15px", fontSize: "calc(0.5vw + 10px)" }}
					onClick={() => setOpenModal(false)}>
					OK
				</Button>
			</Dialog>

			<div style={{ flex: 1 }}></div>

			<CoreButton
				onClick={async ()=>{
					try {
						setLoading(true);
						const response = await businessService.postBusiness({
							name: businessName,
							phone: phone,
							timeZone: "America/Sao_Paulo",
							currency: "BRL",
							sectorId: sectorId,
							categoryId: selectCategoryIds,
							schedulingType: "schedule",
							serviceType: "business",
							status: "inactive",
							language: "portuguese",
						})
						if (response.status >=200 && response.status < 300) {
							setLoading(false);
							const businessData = response.data;
							dispatch(
								businessAction.setBusinessInfo({
									id: businessData.id,
									name: businessData.name,
									email: businessData.email || "",
									createdAt: businessData.createdAt,
								})
							)
							navigate('/menu');
						}
					} catch (error) {
						setLoading(false);
						setModalMessage(error?.message);
						setOpenModal(true);
					}
				}}
				disable={ selectCategoryIds.length < 1 }
			/>
		</CoreContainer>
	);
};

export default SelectCategories;
