import React, { useState } from "react";
import { useLocation } from "react-router";
import { getAuth } from "firebase/auth";
import { useSelector } from "react-redux";
import { useMixpanel } from "react-mixpanel-browser";
import { Button, TextField, Dialog } from "@material-ui/core";
import CoreContainer from "../common/new/container";
import { ArrowForwardIos, Close } from "@material-ui/icons";
import { Divider, MenuItem} from "@mui/material";
import SelectClientDialog from "../selectClientDialog";
import SelectServiceDialog from "../selectServiceDialog";
import { moneyMask } from "../utils";
import bookingTransactionService from "../../services/bookingTransactionService";
import vacanciesService from "../../services/vacanciesService";
import style from '../../styles/global/variables/variables.scss';
import styleNewSchedule from '../../styles/newScheduleDialog/index.scss';

const NewScheduleDialog = (props) => {
	const [isNewSchedule, setIsNewSchedule] = useState(true);
	const initialDate = props.date;
	const location = useLocation();
	const { business } = useSelector((store) => store.business);
	const auth = getAuth();

	const mixpanel = useMixpanel();
	const [client, setClient] = useState("");
	const [date, setDate] = useState(initialDate);
	const [endDate, setEndDate] = useState(initialDate);
	const [time, setTime] = useState("");

	const [openSucessModal, setOpenSucessModal] = useState(false);
	const [openMessage, setOpenMessage] = useState(false);
	const [message, setMessage] = useState("Tudo certo");

	const [startTime, setStartTime] = useState("");
	const [endTime, setEndTime] = useState("");
	const [service, setService] = useState("");
	const [professional, setProfessional] = useState("");
	const [blockReason, setBlockReason] = useState("");

	const [openClientDialog, setOpenClientDialog] = useState(false);
	const [openServiceDialog, setOpenServiceDialog] = useState(false);

	const validateReservationDate = () => {
		if (business) {
			if (auth?.currentUser) {
				let startTimeDate = new Date(date),
					endTimeDate = new Date(date);

				const [hour, minute] = time.split(":").map(Number);
				startTimeDate.setHours(hour, minute);
				endTimeDate.setHours(hour, minute);

				startTimeDate.setMinutes(minute);
				endTimeDate.setMinutes(minute + service.duration);

				vacanciesService
					.checkVacancies(professional.id, startTimeDate.toISOString())
					.then((res) => {
						if (res?.data?.message) {
							setMessage(res?.data?.message);
							setOpenMessage(true);
						}
					})
					.catch((err) => {
						alert(err?.data?.message || 'Ops, ocorreu um erro inesperado')
					});
			}
		}
	};

	const saveReservation = () => {
		if (business) {
			if (auth?.currentUser) {
				let startTimeDate = new Date(date),
					endTimeDate = new Date(date);

				const [hour, minute] = time.split(":").map(Number);
				startTimeDate.setHours(hour, minute);
				endTimeDate.setHours(hour, minute);
				startTimeDate.setMinutes(minute);
				endTimeDate.setMinutes(minute + service.duration);

				let body = {
					bookings: [
						{
							id: null,
							professional_id: professional.id,
							service_id: service.id,
							start_time: startTimeDate.toISOString(),
							end_time: endTimeDate.toISOString(),
							professional,
							date: new Date(date).toISOString(),
							time,
							service,
							feature: {},
							booking: {
								id: null,
								professional_id: professional.id,
								service_id: service.id,
								start_time: startTimeDate,
								end_time: endTimeDate,
							},
							temp_id: service.temp_id,
							businessId: business.id,
							clientId: client.id,
						},
					],
					internalNote: "",
					messageClient: "",
				};

				bookingTransactionService
					.postBookingTransaction(body)
					.then((res) => {
						mixpanel.track("Nova reserva salva - WB", {
							my_custom_prop: "foo",
						});
						window.location.reload();
					})
					.catch((err) => {
						alert("O profissional não está disponível no horário escolhido.");
						console.log(err);
					});
			}
		}
	};

	const formatDateToISO = (date) => {
		return new Date(date).toISOString().slice(0, 19);
	};

	const saveScheduleBlock = () => {
		if (business) {
			if (auth?.currentUser) {
				let startTimeDate = new Date(date),
					endTimeDate = new Date(endDate);

				const [startHour, startMinute] = startTime.split(":").map(Number);
				const [endHour, endMinute] = endTime.split(":").map(Number);

				startTimeDate.setHours(startHour, startMinute);
				endTimeDate.setHours(endHour, endMinute);

				let body = {
					reason: blockReason,
					start_time: formatDateToISO(startTimeDate),
					end_time: formatDateToISO(endTimeDate),
					professional_id: professional.id,
				};

				bookingTransactionService
					.postScheduleBreak(body)
					.then((res) => {
						window.location.reload();
					})
					.catch((err) => {
						const errorMessage = err.response?.data?.message || "Erro ao salvar o bloqueio de horário.";
						alert(errorMessage);
						console.log(errorMessage)
					});
			}
		}
	};

	return (
		<CoreContainer style={{ gap: "16px" }} transparent>
			<div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
				<Close style={{ color: "#3E3E3E", cursor: "pointer" }} onClick={props.onClose}></Close>
			</div>
			<div style={{ display: "flex", justifyContent: "center", gap: "16px", width: "100%" }}>
				<Button
					variant="outlined"
					style={{
						backgroundColor: isNewSchedule ? "#3688EB" : "",
						color: isNewSchedule ? "#FFFFFF" : "#3E3E3E",
						borderRadius: "8px",
						width: "50%",
						textTransform: "none",
					}}
					onClick={() => setIsNewSchedule(true)}>
					Novo agendamento
				</Button>

				<Button
					variant="outlined"
					style={{
						backgroundColor: !isNewSchedule ? "#3688EB" : "",
						color: !isNewSchedule ? "#FFFFFF" : "#3E3E3E",
						borderRadius: "8px",
						width: "50%",
						textTransform: "none",
					}}
					onClick={() => setIsNewSchedule(false)}>
					Novo bloqueio
				</Button>
			</div>

			{isNewSchedule ? (
				<div className="newScheduleContainer">

					<div>
						<p>Cliente</p>
						<Button
							className="selectBlock"
							variant="outlined"
							endIcon={<ArrowForwardIos/>}
							onClick={() => setOpenClientDialog(true)}>
							{client ? client.name : "Selecione um cliente"}
						</Button>
					</div>

					<div>
						<p>Data e Hora</p>
						<div className="inputTimeContainer">
							<TextField
								className="inputTime"
								value={date}
								variant="outlined"
								onChange={(e) => { setDate(e.target.value) }}
								type="date"
							/>
							<TextField
								className="inputTime"
								value={time}
								variant="outlined"
								onChange={(e) => { setTime(e.target.value) }}
								type="time"
							/>
						</div>
					</div>

					<div>
						<p>Serviço</p>
						<Button
							variant="outlined"
							className="selectBlock"
							endIcon={<ArrowForwardIos/>}
							onClick={() => setOpenServiceDialog(true)}>
							{service ? (
								<div className="serviceText">
									<p>{service.serviceName}</p>
									<p>{service.duration + "m"}</p>
									<p>{moneyMask(service?.price, business.currency)}</p>
								</div>
							) : ( "Selecione um serviço" )}
						</Button>
					</div>

					<div>
						<p>Profissional</p>
						<TextField
							select
							variant="outlined"
							value={professional}
							onChange={(e) => setProfessional(e.target.value)}
							fullWidth>
							{business?.professionals?.map((prof) => (
								<MenuItem className="serviceText" key={prof.id} value={prof}> <p>{prof.name}</p></MenuItem>
							))}
						</TextField>
					</div>
				</div>
			) : (
				<div  className="newScheduleContainer">
					<div>
						<p>Início</p>
						<div className="inputTimeContainer">
							<TextField
								className="inputDateHours"
								value={date}
								variant="outlined"
								onChange={(e) => { setDate(e.target.value) }}
								type="date"
							/>

							<TextField
								className="inputDateHours"
								value={startTime}
								variant="outlined"
								onChange={(e) => { setStartTime(e.target.value) }}
								type="time"
							/>
						</div>
					</div>
					<div>
						<p>Término</p>
						<div className="inputTimeContainer">
							<TextField
								className="inputDateHours"
								value={endDate}
								variant="outlined"
								onChange={(e) => {
									setEndDate(e.target.value);
								}}
								type="date"
							/>
							<TextField
								className="inputDateHours"
								value={endTime}
								variant="outlined"
								onChange={(e) => {
									setEndTime(e.target.value);
								}}
								type="time"
							/>
						</div>
					</div>

					<div>
						<p>Profissional</p>
						<TextField
							select
							variant="outlined"
							value={professional}
							onChange={(e) => setProfessional(e.target.value)}
							fullWidth>
							{business?.professionals?.map((prof) => (
								<MenuItem key={prof.id} value={prof}>
									{prof.name}
								</MenuItem>
							))}
						</TextField>
					</div>
					<div>
						<p> Qual o motivo do bloqueio? (opcional)</p>
						<TextField
							variant="outlined"
							value={blockReason}
							onChange={(e) => setBlockReason(e.target.value)}
							fullWidth
						/>
					</div>
					
				</div>
			)}

			<button className="saveButton"	onClick={() => { isNewSchedule ? validateReservationDate() : saveScheduleBlock() }}>
				<p>Salvar</p>
			</button>

			<Dialog
				open={openClientDialog}
				fullScreen
				PaperProps={{
					style: { backgroundColor: "transparent" },
				}}>
				<SelectClientDialog onClose={() => setOpenClientDialog(false)} select={setClient} />
			</Dialog>

			<Dialog
				open={openServiceDialog}
				fullScreen
				PaperProps={{
					style: { backgroundColor: "transparent" },
				}}>
				<SelectServiceDialog onClose={() => setOpenServiceDialog(false)} select={setService} />
			</Dialog>

			<Dialog
				open={openMessage}
				PaperProps={{
					style: { borderRadius: 20, maxWidth: 400 },
				}}>
				<p style={{ color: "#3E3E3E", fontSize: "18px", padding: "16px", textAlign: "center" }}>{message}</p>
				<Divider/>
				<div style={{ display: "flex", justifyContent : 'space-around' }}>
					<Button style={{ padding: "16px" }} onClick={() => setOpenMessage(false)}>
						Cancelar
					</Button>
					<Divider flexItem orientation="vertical"></Divider>
					<Button
						style={{ padding: "16px" }}
						onClick={() => { setOpenMessage(false); saveReservation(); }}>
						Continuar	mesmo assim? 
					</Button>
				</div>
			</Dialog>
		</CoreContainer>
	);
};

export default NewScheduleDialog;
