import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import style from '../../../styles/reservationCard/index.scss';

const ReservationCard = (props) => {
	const { clientName, serviceName, professionalName, onClick, color, startTime, endTime } = props;
	const [bgColor,setBgColor] = useState(''); 

	useEffect(()=>{
		const colors = [ 'bgCard01', 'bgCard02', 'bgCard03' ];
		const bgRandomColors = colors[Math.floor(Math.random() * colors.length)];
		setBgColor(bgRandomColors);
	},[])
	

	return (
		<>
			<div onClick={onClick} className = {`cardContainer ${bgColor}`} >
				{" "}
				<Typography>
					{startTime + " - " + endTime}
				</Typography>
				<p className="serviceAndClient">
					{clientName + " - " + serviceName}
				</p>
			</div>
		</>
	);
};
export default ReservationCard;
