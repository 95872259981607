import React, { useEffect, useState } from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import CoreContainer from "../common/new/container";
import { ArrowBackIos } from "@material-ui/icons";
import CoreButton from "../common/new/button";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import useStyles from "../address/styles";
import SpinnerLoading from "../common/spinnerLoading/SpinnerLoading";
import { moneyMask } from "../utils";
import professionalServiceService from "../../services/ServicesService";
import api from "../../services/api";
import styles from '../../styles/selectServiceDialog/style.scss';

const SelectServiceDialog = (props) => {
	const classes = useStyles();

	const { business } = useSelector((store) => store.business);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [loading, setLoading] = useState(false);
	const [services, setServices] = useState([]);
	const { sector } = useSelector((store) => store.sector);
	const [editPriceDialogOpen, setEditPriceDialogOpen] = useState(false);
	const [selectedService, setSelectedService] = useState(null);
	const [newPrice, setNewPrice] = useState("");

	useEffect(() => {
		setLoading(true);
		if (sector?.length > 0) {
			setSelectedCategory(sector[0].categories[0]);
			professionalServiceService
				.getServicesByCategoryId(business.id, sector[0].categories[0].id)
				.then((res) => {
					setServices(res.data);
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
				});
		}
	}, [sector]);

	const handleEditPrice = (service) => {
		setSelectedService(service);
		setNewPrice(service.price);
		setEditPriceDialogOpen(true);
	};

	const handleUpdatePrice = () => {
		const updatedService = {
			serviceId: selectedService.id,
			duration: selectedService.duration,
			price: parseFloat(newPrice) * 100,
			pricingTypeId: selectedService.pricingTypeId || "",
		};

		api
			.put(`business/${business.id}/business_service`, {
				businessServices: [updatedService],
			})
			.then(() => {
				setEditPriceDialogOpen(false);
				setServices((prevServices) =>
					prevServices.map((service) =>
						service.id === selectedService.id ? { ...service, price: updatedService.price } : service
					)
				);
			});
	};

	return (
		<CoreContainer style={{ gap: "16px" }} transparent>
			<div style={{ display: "flex" }}>
				<ArrowBackIos style={{ width: "30px", color: " #616161", cursor: "pointer" }} onClick={props.onClose} />
			</div>
			<div className="categoriesTitle">
				<p>Selecione uma categoria:</p>
			</div>
			<div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', gap: '1rem'}}>
			<Swiper 
        spaceBetween={10} 
        slidesPerView="auto" 
        style={{ padding: '10px 0', width: '100%' }}
      >
        {sector?.length > 0 &&
          sector[0].categories?.map((category) => (
            <SwiperSlide key={category.id} style={{ width: 'auto', paddingBottom: '50px'}}>
              <Button
                onClick={() => {
                  setSelectedCategory(category);
                  setLoading(true);
                  professionalServiceService
                    .getServicesByCategoryId(business.id, category.id)
                    .then((res) => {
                      setServices(res.data);
                      setLoading(false);
                    })
                    .catch((error) => {
                      setLoading(false);
                    });
                }}
                variant="outlined"
                style={{
                  backgroundColor: selectedCategory?.id === category?.id ? '#3688EB' : '',
                  color: selectedCategory?.id === category?.id ? '#FFFFFF' : '#3E3E3E',
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontSize: '16px',
                }}
              >
                {category.name}
              </Button>
            </SwiperSlide>
          ))}
    	</Swiper>

			<SpinnerLoading loading={loading} />
			<div className="servicesTitle">
				<p>Selecione um serviço:</p>
			</div>
			<div className="servicesGrid">
				<p>Nome</p>
				<p>Tempo</p>
				<p>Preço</p>
			</div>
			
			{services.length > 0 ? (
				<div className="servicesDadContainer">
					{services.map((service) => (
						<div key={service.id} >
							<div
								onClick={() => {
									props.select(service);
									props.onClose();
								}}
							>
								<div className="servicesContainer">
									<p>{service.serviceName}</p>
									<p>{service.duration + 'm'}</p>
									<p style={{ color: '#3689ea', fontSize: '16px', cursor: 'pointer' }}
										onClick={(e) => {
											e.stopPropagation();
											handleEditPrice(service);
										}}
									>
										{moneyMask(service?.price, business.currency)}
									</p>
								</div>
							</div>
						</div>
					))}
				</div>
			) : (
				<div className="captionContainer">
					<p>Não há serviços para categoria selecionada</p>
				</div>
			)}
			</div>

			<Dialog
				open={editPriceDialogOpen}
				onClose={() => setEditPriceDialogOpen(false)}
				PaperProps={{ style: { borderRadius: 12 } }}>
				<div style={{ padding: "16px" }}>
					<div style={{ display: "flex" }}>
						<ArrowBackIos style={{ width: "30px", color: "#616161", cursor: 'pointer' }} onClick={() => setEditPriceDialogOpen(false)} />
						<Typography style={{ color: "#3E3E3E", fontSize: "18px", fontWeight: "bold", marginBottom: "8px" }}>
							Editar valor do serviço
						</Typography>
					</div>

					<DialogContent>
						<TextField
							label="Novo Preço"
							type="number"
							value={newPrice}
							onChange={(e) => setNewPrice(e.target.value)}
							fullWidth
							variant="outlined"
						/>
					</DialogContent>
					<DialogActions>
						<CoreButton onClick={handleUpdatePrice} text="Salvar" color="#3689ea" />
					</DialogActions>
				</div>
			</Dialog>
		</CoreContainer>
	);
};

export default SelectServiceDialog;
