import { ReactComponent as LogoSvg } from "../../../assets/icons/logo.svg";
import { ReactComponent as LogoWhiteSvg } from "../../../assets/icons/logoWhite.svg";
import { ReactComponent as LinkSvg } from "../../../assets/icons/link.svg";
import { ReactComponent as ConfigSvg } from "../../../assets/icons/config.svg";
import { ReactComponent as SignatureSvg } from "../../../assets/icons/signature.svg";
import { ReactComponent as QuestionSvg } from "../../../assets/icons/question.svg";
import { ReactComponent as ExitSvg } from "../../../assets/icons/exit.svg";
import { ReactComponent as ClientsSvg } from "../../../assets/icons/clients.svg";

export const Logo = LogoSvg;
export const LogoWhite = LogoWhiteSvg;
export const Link = LinkSvg;
export const Config = ConfigSvg;
export const Signature = SignatureSvg;
export const Question = QuestionSvg;
export const Exit = ExitSvg;
export const Clients = ClientsSvg;
