import React from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import { Button } from "@mui/material";

const CoreButton = (props) => {
	const {
		toLogin,
		text,
		toCode,
		toData,
		toWelcome,
		toSchedule,
		onClick,
		id,
		params,
		disable,
		invertColors,
		style,
		color,
	} = props;

	let navigate = useNavigate();

	const handleClick = () => {
		if (!disable) {
			if (onClick) {
				onClick();
			}

			if (toLogin) {
				navigate("/login", { state: params });
			} else if (toCode) {
				navigate("/login/code", { state: params });
			} else if (toData) {
				navigate("/login/data", { state: params });
			} else if (toWelcome) {
				navigate("/welcome", { state: params });
			} else if (toSchedule) {
				navigate("/menu", { state: params });
			}

		}
	};

	return (
		<Button
			id={id}
			onClick={handleClick}
			style={{
				...style,
				fontSize: "18px",
				padding: "8px 16px",
				borderRadius: "8px",
				backgroundColor: disable ? "gray" : color ? color : invertColors ? "transparent" : "#39ADC4",
				border: "solid 1px #fff",
				color: invertColors ? "#39ADC4" : "#fff",
				cursor: disable ? "unset" : "pointer",
				overflow: "hidden",
				width: "100%",
				textTransform: "none",
				height: "45px",
				maxHeight: "45px",
			}}
			variant="outlined"
			disabled={disable}	
		>
			{text ? text : "Avançar"}
		</Button>
	);
};

export default CoreButton;
